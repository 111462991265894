import React, { Fragment } from 'react'
import { itConsultingBreadcrumbs, metaItConsulting } from '../../../../Utilites'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'
import { AppConstant } from '../../../../AppConstant'

const ItConsulting = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaItConsulting}/>
        <TopScreen breadcrumbDetails={itConsultingBreadcrumbs}/>
        <CommonService serviceData={AppConstant.detailServices.itConsulting}/>
    </Fragment>
  )
}

export default ItConsulting