import React, { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup';
const CounterSection = () => {
   const [inView,setInView] = useState(false);
   const [isWideScreen, setIsWideScreen] = useState(false);
   const sectionRef = useRef(null);
   
   useEffect(() => {
      // Check the window width on mount and on resize
      checkWidth();
      window.addEventListener('resize', checkWidth);
  
      return () => {
        window.removeEventListener('resize', checkWidth);  // Cleanup listener on unmount
      };
    }, []);

   // useEffect(()=> {
   //    const observer = new IntersectionObserver((entries)=> {
   //       entries.forEach(entry => {
   //          if(entry.isIntersecting){
   //             setInView(true);
   //          }
   //       })
   //    },{
   //       root:null,
   //       threshold: 0.5
   //    });
   //    console.log('sectionRef.current',sectionRef.current)
   //    if(sectionRef.current) {
   //       observer.observe(sectionRef.current);
   //    }

   //    return () => {
   //       if(sectionRef.current) {
   //          observer.unobserve(sectionRef.current)
   //       }
   //    }
   // },[])

   useEffect(() => {
      const handleScroll = () => {
        if (sectionRef.current) {
          const rect = sectionRef.current.getBoundingClientRect();
          // Check if the section is within the viewport
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            setInView(true);  // Section is in view, trigger the counters
          }
        }
      };
  
      // Listen for the scroll event
      window.addEventListener('scroll', handleScroll);
  
      // Trigger initial visibility check on mount
      handleScroll();
  
      return () => {
        window.removeEventListener('scroll', handleScroll);  // Cleanup on unmount
      };
    }, []);

   const checkWidth = () => {
      if (window.innerWidth >= 1024) {
        setIsWideScreen(true);  // Set to true if the width is larger than 1024px
      } else {
        setIsWideScreen(false);  // Set to false if smaller than 1024px
      }
    };

  return (
    <section class="counter-area tp-counter-3-bg" 
    ref={sectionRef} style={{background:'url(assets/img/counter/counter-3-bg-1.jpg)'}}>
            <div class="container">
               <div class="row">
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-project-management"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="3480"  class="purecounter">
                                 {inView && <CountUp start={0} end={20} duration={3} />}
                              </span>
                           </h3>
                           <p>Project Completed</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-consultant"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="15"  class="purecounter">
                                 {inView && <CountUp start={0} end={15} duration={3} />}
                              </span>
                           </h3>
                           <p>IT Specialist</p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-healthcare"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="20"  class="purecounter">
                                 {inView && <CountUp start={0} end={20} duration={3} />}
                              </span>
                           </h3>
                           <p>Happy Clients </p>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-counter-3-item text-center pb-50">
                        <div class="tp-counter-3-item-icon">
                           <div class="tp-counter-3-item-icon-border">
                              <i class="flaticon-solution-1"></i>
                           </div>
                        </div>
                        <div class="tp-counter-3-item-content">
                           <h3 class="tp-counter-3-item-count">
                              <span data-purecounter-duration="1" data-purecounter-end="256"  class="purecounter">
                                {inView&& <CountUp start={0} end={256} duration={3} />}
                              </span>
                           </h3>
                           <p>Smart Solution </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
  )
}

export default CounterSection