import React, { Fragment } from 'react'
import { AppConstant } from '../../../../AppConstant'
import { metaWebDevelopment, webDevelopmentBreadcrumbs } from '../../../../Utilites'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'

const WebDevelopment = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaWebDevelopment}/>
        <TopScreen breadcrumbDetails={webDevelopmentBreadcrumbs}/>
        <CommonService serviceData={AppConstant.detailServices.webDevelopment}/>
    </Fragment>
  )
}

export default WebDevelopment