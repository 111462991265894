import React, { Fragment } from 'react'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'
import { aiDevelopmentBreadcrumbs,  metaAiDevelopment } from '../../../../Utilites'
import { AppConstant } from '../../../../AppConstant'

const AiDevelopment = () => {
    return (
        <Fragment>
            <MetaTagComponent metaDetails={metaAiDevelopment} />
            <TopScreen breadcrumbDetails={aiDevelopmentBreadcrumbs} />
            <CommonService serviceData={AppConstant.detailServices.aiDevelopment} />
        </Fragment>
    )
}

export default AiDevelopment