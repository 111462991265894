import React, { Fragment, useEffect, useRef, useState } from "react";
import Topbar from "./Topbar";
import MobileMenu from "./Mobile/MobileMenu";
import MenuLinks from "./MenuLinks";
import { Link } from "react-router-dom";
import { AppConstant } from "../../AppConstant";

const Header = () => {
  const headerRef = useRef(null);
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const [isMobMenuOpened, setIsMobMenuOpened] = useState(false);

  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  const openMobileMenu = () => {
    setIsMobMenuOpened(true);
  };

  return (
    <Fragment>
      <header>
        <div className="tp-header-area tp-header-3 tp-header-height">
          <Topbar />
          <div
            id="header-sticky"
            className={`tp-header-3-wrap white-bg-b d-flex justify-content-between ${
              sticky.isSticky ? "header-sticky" : ""
            }`}
            ref={headerRef}
          >
            <div className="tp-header-3-main d-flex">
              <div
                className="logo"
                // data-background="assets/img/shape/logo-shape-1.png"
                // style={{ background: "url(assets/img/shape/logo-shape-1.png)" }}
              >
                <Link to="/">
                  <img
                    data-width="100"
                    style={{ maxWidth: "180px" }}
                    src="assets/img/logo/Meta_fox.png"
                    alt=""
                  />
                </Link>
              </div>
              <div className="main-menu tp-header-3-menu d-none d-xl-block">
                <MenuLinks />
              </div>
            </div>
            <div className="tp-header-right d-flex align-items-center">
              <div className="tp-header-4-contact d-none d-md-flex align-items-center">
                <div className="tp-header-4-contact-icon">
                  <i className="fa-solid fa-phone-flip"></i>
                </div>
                <div className="tp-header-4-contact-content">
                  <span>Call Anytime</span>
                  <a href={`tel:${AppConstant.CALL.number}`}>+(91) {AppConstant.CALL.number}</a>
                </div>
              </div>
              <div className="tp-header-btn ml-35 mr-30 d-none d-lg-block">
                <Link className="tp-btn" to="contact">Get Solution</Link>
              </div>
              <div className="offcanvas-btn d-xl-none ml-30">
                <button
                  className="offcanvas-open-btn"
                  type="button"
                  onClick={openMobileMenu}
                >
                  <i className="fa-solid fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isMobMenuOpened && (
        <MobileMenu
          key={"mob-menu"}
          setIsMobMenuOpened={setIsMobMenuOpened}
          isMobMenuOpened={isMobMenuOpened}
        />
      )}
    </Fragment>
  );
};

export default Header;
