import React, { Fragment } from 'react'

const HomeBanner = () => {
  return (
    <Fragment>
        <section
  className="banner-area tp-banner-5-bg"
  data-background="assets/img/banner/five/banner-5-bg-1.jpg"
  style={{ backgroundImage: 'url("assets/img/banner/five/banner-5-bg-1.jpg")' }}
>
  <div className="container">
    <div className="row align-items-end">
      <div className="col-lg-6">
        <div className="tp-banner-5-wrapper">
          <span
            className="tp-banner-5-sub-title wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".5s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.5s",
              animationName: "fadeInUp"
            }}
          >
            Expertise You Can Trust
          </span>
          <h1
            className="tp-banner-5-title wow fadeInUp"
            data-wow-duration="1.2s"
            data-wow-delay=".7s"
            style={{
              visibility: "visible",
              animationDuration: "1.2s",
              animationDelay: "0.7s",
              animationName: "fadeInUp"
            }}
          >
            Your <span className='primary-span'>Trusted</span> Partner for Digital Transformation
          </h1>
          <div
            className="tp-banner-5-info d-flex align-items-center wow fadeInUp"
            data-wow-duration="1.4s"
            data-wow-delay=".9s"
            style={{
              visibility: "visible",
              animationDuration: "1.4s",
              animationDelay: "0.9s",
              animationName: "fadeInUp"
            }}
          >
            <div className="tp-banner-5-btn">
              <a className="tp-btn" href="about.html">
                Discover More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="tp-banner-5-thumb">
          <div className="tp-banner-5-thumb-1">
            <img src="assets/img/banner/five/banner-5-thumb-1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div className="tp-slider-2-social d-none d-lg-block">
    <span>Follow us _ </span>
    <a href="#">Fb</a>
    <a href="#">Tw</a>
    <a href="#">Yt</a>
    <a href="#">In</a>
  </div> */}
</section>

    </Fragment>
  )
}

export default HomeBanner