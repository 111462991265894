import React, { Fragment } from 'react'
import { eCommerceBreadcrumbs, metaItConsulting } from '../../../../Utilites'
import { AppConstant } from '../../../../AppConstant'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'

const ECommerceDevelopment = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaItConsulting}/>
        <TopScreen breadcrumbDetails={eCommerceBreadcrumbs}/>
        <CommonService serviceData={AppConstant.detailServices.eCommerce}/>
    </Fragment>
  )
}

export default ECommerceDevelopment