import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const WhyChoose = () => {
  return (
    <Fragment>
      <section className="video-area tp-video-two mtb-100">
        <div
          className="tp-video-two-bg  p-relative"
          data-background="assets/img/video/video-2-thumb-1.jpg"
          style={{
            backgroundImage: 'url("assets/img/video/video-2-thumb-1.jpg")',
          }}
        >
          <div className="tp-video-two-wrap  d-none d-lg-block">
            <div className="tp-video-two-shape-one">
              <img src="assets/img/shape/video-2-shape-1.png" alt="" />
            </div>
            <div className="tp-video-two-shape-two">
              <img src="assets/img/shape/video-2-shape-2.png" alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="offset-xl-7 col-xl-5 offset-lg-6 col-lg-6">
              <div
                className="tp-video-two-wrapper p-relative pb-65 pt-55 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.4s",
                  animationName: "fadeInRight",
                }}
              >
                <div className="tp-section tp-section-two mb-35">
                  <span className="tp-section-sub-title">Why MetaFox?</span>
                  <h4 className="tp-section-title">
                    Your Trusted Partner for Digital Success
                  </h4>
                  <div className="tp-section-title-wrapper">
                    <p>
                      At MetaFox, we're committed to delivering exceptional IT
                      solutions that drive your business forward. Here's why you
                      should choose us:
                    </p>
                  </div>
                </div>
                <div className="tp-video-two-list mb-50">
                  <ul>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-icon.svg" alt="" />
                      </span>
                      Expertise and Innovation:
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-icon.svg" alt="" />
                      </span>
                      Client-Centric Approach:
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-icon.svg" alt="" />
                      </span>
                      Quality Assurance:
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-icon.svg" alt="" />
                      </span>
                      Timely Delivery:
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-icon.svg" alt="" />
                      </span>
                      Transparent Communication:
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-icon.svg" alt="" />
                      </span>
                      Cost-Effective Solutions:
                    </li>
                  </ul>
                </div>
                <div className="tp-video-two-btn">
                  <Link className="tp-btn" to={'/contact'}>
                    Connect US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WhyChoose;
