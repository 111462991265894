import React, { Fragment } from 'react'
import { metaUIdevelopment, uiDevelopmentService } from '../../../../Utilites'
import { AppConstant } from '../../../../AppConstant'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'

const UIDevelopment = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaUIdevelopment}/>
        <TopScreen breadcrumbDetails={uiDevelopmentService}/>
        <CommonService serviceData={AppConstant.detailServices.uiDesign}/>
    </Fragment>
  )
}

export default UIDevelopment