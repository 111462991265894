import React, { Fragment } from "react";
import SideService from "./SideService";

const CommonService = ({ serviceData }) => {
  return (
    <section className="services-area pt-115 pb-80">
      <div className="container">
        <div className="row">
          <SideService />
          <div className="col-lg-8">
            {serviceData &&
              serviceData.map((s) => {
                return (
                  <Fragment>
                    <h1 className="service-title" 
                      data-aos="fade-up"
                      data-aos-duration="900"
                      data-aos-delay="100" >
                      {s.main_title}
                    </h1>
                    <div className="main_description" data-aos="fade-up"
                      data-aos-duration="900"
                      data-aos-delay="100" >
                      <p>{s.main_title_description}</p>
                      <p>{s.main_title_description_two}</p>
                    </div>
                    <div class="tp-services-details-wrapper row">
                      <div class="tp-services-details-main-thumb mb-30 w-img" data-aos="fade-up"
                        data-aos-duration="950"
                        data-aos-delay="100">
                        <img
                          src={s.image_url}
                          alt={s.image_url}
                        />
                      </div>
                      <div className="" data-aos="fade-up"
                        data-aos-duration="950"
                        data-aos-delay="100">
                        <h2 class="tp-services-details-title mb-5">
                          {s.section_one_title}
                        </h2>
                        <p>
                          {s.section_one_title_description}
                        </p>
                        <div className="detail-service">
                          <ul>
                            {s.detail_lists &&
                              s.detail_lists.map((dl, i) => {
                                return (
                                  <li>
                                    <span className="detail-service-title h3 text-dark">
                                      <strong>{dl.service_title}</strong>
                                    </span>
                                    <span className="list-description">
                                      {" "}
                                      {dl.span_detail}
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div class="service_2 tp-services-details-check align-items-center mt-50"
                        data-aos="fade-up"
                        data-aos-duration="950"
                        data-aos-delay="100"
                      >
                        <h2>{s.section_two_title}<span className="primary-span"> {s.section_two_title_span}</span></h2>
                        <div className="mt-30 mb-50">
                          {s.why_choose_meta_fox &&
                            s.why_choose_meta_fox.map((m) => {
                              return (
                                <div class="tp-services-details-check-item d-flex align-items-center mb-15">
                                  <i class="fa-light fa-check"></i>
                                  <span>
                                    <strong className="h6">{m.label}</strong>
                                    &nbsp;
                                    {m.description}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="service_3" data-aos="fade-up"
                        data-aos-duration="950"
                        data-aos-delay="100">
                        <h2 class="tp-services-details-title mb-5">
                          {s.section_three_title}
                        </h2>
                        <p>
                          {s.section_three_title_description}
                        </p>
                        <div className="detail-service">
                          <ul>
                            {s.section_three &&
                              s.section_three.map((dl, i) => {
                                return (
                                  <li>
                                    <span className="detail-service-title h3 text-dark">
                                      <strong>{dl.label}</strong>
                                    </span>
                                    <span className="list-description">
                                      {" "}
                                      {dl.description}
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div class="tp-services-details-faq">
                        <div class="row">
                          <div class="col-lg-6"
                            data-aos="zoom-in"
                            data-aos-duration="950"
                            data-aos-delay="100">
                            <div class="tp-services-details-faq-item d-flex align-items-center mb-50">
                              <div class="tp-services-details-faq-item-icon">
                                <i class="flaticon-medal"></i>
                              </div>
                              <div class="tp-services-details-faq-item-content">
                                <h4 class="tp-services-details-faq-title">
                                  Why IT Solution?
                                </h4>
                                <span>
                                  We always try to give best services
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6"
                            data-aos="zoom-in"
                            data-aos-duration="950"
                            data-aos-delay="100">
                            <div class="tp-services-details-faq-item d-flex align-items-center mb-50">
                              <div class="tp-services-details-faq-item-icon">
                                <i class="flaticon-medal"></i>
                              </div>
                              <div class="tp-services-details-faq-item-content">
                                <h4 class="tp-services-details-faq-title">
                                  Best Solution
                                </h4>
                                <span>
                                  We always try to give best services
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonService;
