import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import About from "../about/About";
import NotFound from "../not-found/NotFound";
import ContactUs from "../contact-us/ContactUs";
import AeonServices from "../pages/Service/AeonServices";
import DigitalMarketingService from "../pages/Service/digital-marketing-service/DigitalMarketingService";
import { AppConstant } from "../../AppConstant";
import WebDevelopment from "../pages/Service/web-development/WebDevelopment";
import MobileAppDevelopment from "../pages/Service/mobile-app-development/MobileAppDevelopment";
import AiDevelopment from "../pages/Service/ai-development/AiDevelopment";
import ItConsulting from "../pages/Service/it-consulting/ItConsulting";
import ECommerceDevelopment from "../pages/Service/e-commerce/ECommerceDevelopment";
import UIDevelopment from "../pages/Service/ui-development/UIDevelopment";

const AeonRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={AppConstant.ROUTES.ABOUT} element={<About />} />
      <Route path={AppConstant.ROUTES.CONTACT} element={<ContactUs />} />
      <Route path={AppConstant.ROUTES.SERVICES.PROVIDE_SERVICES} element={<AeonServices />}/>
      <Route path={AppConstant.ROUTES.SERVICES.DIGITAL_MARKETING} element={<DigitalMarketingService />} />
      <Route path={AppConstant.ROUTES.SERVICES.WEB_DEVELOPMENT} element={<WebDevelopment />} />
      <Route path={AppConstant.ROUTES.SERVICES.APP_DEVELOPMENT} element={<MobileAppDevelopment />} />
      <Route path={AppConstant.ROUTES.SERVICES.AI_DEVELOPMENT} element={<AiDevelopment />} />
      <Route path={AppConstant.ROUTES.SERVICES.IT_CONSULTING} element={<ItConsulting />} />
      <Route path={AppConstant.ROUTES.SERVICES.ECOMMERCE} element={<ECommerceDevelopment />} />
      <Route path={AppConstant.ROUTES.SERVICES.UI_DESIGN} element={<UIDevelopment />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AeonRoutes;
