import React from "react";
import FeatureSection from "./Sections/FeatureSection";
import AboutSection from "./Sections/AboutSection";
import OffersSection from "./Sections/OffersSection";
import CtaSection from "./Sections/CtaSection";
import SolutionArea from "./Sections/SolutionArea";
import CounterSection from "./Sections/CounterSection";
import BrandSection from "./Sections/BrandSection";
import SliderSection from "./Sections/SliderSection";
import MetaTagComponent from "../common/Meta/MetaTagComponent";
import { metaDetailsHome } from "../../Utilites";
import AeonPartners from "../common/Aeon-Partners/AeonPartners";
import HomeBanner from "./Sections/HomeBanner";
import WhyChoose from "./Sections/WhyChoose";

const Home = () => {
  
  return (
    <>
      <MetaTagComponent metaDetails={metaDetailsHome} />
      <main>
        <HomeBanner/>
        <FeatureSection />
        <AboutSection />
        <CounterSection />
        <WhyChoose/>
      </main>
    </>
  );
};

export default Home;
