import { AppConstant } from "./AppConstant";

export const metaDetailsHome = {
  title: "MetaFox | web design and development company",
  description:
    "Experience the expertise of a leading web design and development company, dedicated to delivering high-quality and innovative digital solutions ",
  keyWord: "MetaFox",
};

export const metaDetailsAbout = {
  title: "About Us | Best web design and development company",
  description:
    "Aeon Tech Delivering top-notch web design and development solutions tailored to meet your unique business requirements",
  keyWord: "MetaFox",
};

export const metaDetailsServices = {
  title: "MetaFox | One Place Solutions for Digital Web Services",
  description:
    "One stop solutions for your web design, mobile application development, branding and custom build website dev services. Contact us today for a custom quote.",
  keyWord: "MetaFox",
};

export const metaDigitalMarketing = {
  title: "MetaFox | Elevate Your Online Presence with Meta Fox Digital Marketing",
  description:
    "Accelerate your digital journey with Meta Fox's expert digital marketing services. From SEO and PPC to social media and content marketing, we deliver results.",
  keyWord: "MetaFox",
};

export const metaWebDevelopment = {
  title: "MetaFox | Elevate Your Digital Presence with MetaFox Web Development",
  description:
    "Experience cutting-edge web development solutions with MetaFox. Our expert team crafts stunning, responsive websites that captivate users and drive business growth.",
  keyWord: "web development, web design, web application, e-commerce",
};

export const metaMobileAppDevelopment = {
  title: "Revolutionize Your Business with Cutting-Edge Mobile Apps",
  description:
    "Elevate your brand and reach a wider audience with our innovative mobile app development services. From concept to launch, we deliver exceptional apps that drive user engagement and business growth.",
  keyWord: "mobile app development, app development, iOS app development, Android app development, cross-platform app development, hybrid app development, mobile app design, UI/UX design, mobile app testing, app store optimization, ASO, mobile backend development, mobile app maintenance, mobile app security",
};

export const metaAiDevelopment = {
  title: "AI Solutions | Custom AI Development | Machine Learning",
  description: "Elevate Your Business with AI: Expert AI Consulting and Development",
  keyWord: "AI Development Services | Machine Learning | Data Science | AI Consulting",
};

export const metaItConsulting = {
  title: "IT Consulting | Digital Transformation | Cloud Solutions",
  description: "Elevate Your Business with Expert IT Consulting and Strategy",
  keyWord: "IT Consulting Services | Digital Transformation | Cloud Solutions | IT Strategy",
};

export const metaUIdevelopment = {
  title: "Professional UI/UX Design Services | Enhance User Experience & Interface Design",
  description: "Transform your digital products with MetaFox’s expert UI/UX design services. We create intuitive, user-centered designs that enhance usability, engagement, and overall user satisfaction across all devices.",
  keyWord: `UI/UX design services, user interface design, user experience design, responsive web design, mobile UI design, interactive design, design systems, usability testing, prototyping,
  wireframing, UI/UX design agency, custom UI design, user research, accessibility design, digital product design.`,
};

export const breadcrumbDetailsAbout = {
  activeElement:'About',
  title:'About Us'
}
export const breadcrumbDetailsServices = {
  activeElement:'Services',
  title:'Our Services'
}
export const breadcrumbDetailsContactus = {
  activeElement:'Contact Us',
  title:'Contact Us'
}
export const breadcrumbDetailsWebDevelopment = {
  activeElement:'Service Details',
  title:'Service Details'
}

export const digitalMarketingBreadcrumbs = {
  activeElement:'Digital Marketing',
  title:'Digital Marketing'
}

export const webDevelopmentBreadcrumbs = {
  activeElement:'Web Development',
  title:'Web Development'
}

export const mobileDevelopmentBreadcrumbs = {
  activeElement:'Mobile App Development',
  title:'Mobile App Development'
}

export const itConsultingBreadcrumbs = {
  activeElement:'IT Consulting & Strategy',
  title:'IT Consulting & Strategy'
}

export const aiDevelopmentBreadcrumbs = {
  activeElement:'Ai Development',
  title:'Ai Development'
}

export const eCommerceBreadcrumbs = {
  activeElement:'E-Commerce Development',
  title:'E-Commerce Development'
}

export const uiDevelopmentService = {
  activeElement:'UI-UX Development',
  title:'UI-UX Development'
}

export const serviceCardLists = [
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/discovery.svg",
    title: "Digital Marketing Services",
    description:`Accelerate your digital journey with expert consulting, strategy, and implementation services.`,
    link: `/${AppConstant.ROUTES.SERVICES.DIGITAL_MARKETING}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/web-development.svg",
    title: "Web Development & Design",
    description:`Build robust, scalable, and user-centric web applications tailored to your specific needs.`,
    link: `/${AppConstant.ROUTES.SERVICES.WEB_DEVELOPMENT}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/genai.svg",
    title: "AI Development Services",
    description:`Unlock the potential of your data with advanced analytics and machine learning to drive informed decision-making.`,
    link: `/${AppConstant.ROUTES.SERVICES.AI_DEVELOPMENT}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/Mobiledev.svg",
    title: "Mobile App Development",
    description:` Create stunning, high-performance mobile apps for iOS, Android, and web, reaching a wider audience.`,
    link: `/${AppConstant.ROUTES.SERVICES.APP_DEVELOPMENT}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/cloud.svg",
    title: "IT Consulting & Strategy",
    description:`Elevate your business with expert IT consulting services, tailored solutions, and actionable strategies.`,
    link: `/${AppConstant.ROUTES.SERVICES.IT_CONSULTING}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/ecommerce.svg",
    title: "E-Commerce Development",
    description:`Build high-performance, user-friendly e-commerce platforms to drive online sales and customer satisfaction.`,
    link: `/${AppConstant.ROUTES.SERVICES.ECOMMERCE}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/UIUX-service.svg",
    title: "UI/UX Design",
    description:`Create visually stunning and intuitive user interfaces that captivate your audience.`,
    link: `/${AppConstant.ROUTES.SERVICES.UI_DESIGN}`
  },
  {
    divClass:"col-lg-3 col-md-6 col-sm-12 pm-to-5 services-list-card",
    imgSrc:"assets/img/services/dedicated-staff.svg",
    title: "Hire Dedicated Development Team",
    description:`Access top-tier developers and accelerate your project timelines with our flexible hiring models.`,
    link: '/hire-dedicated-service'
  }
]
