import React, { Fragment } from 'react'
import CommonService from '../Details-page/CommonService'
import { AppConstant } from '../../../../AppConstant'
import TopScreen from '../../../common/TopScreen/TopScreen'
import { digitalMarketingBreadcrumbs, metaDigitalMarketing } from '../../../../Utilites'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'

const DigitalMarketingService = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaDigitalMarketing}/>
        <TopScreen breadcrumbDetails={digitalMarketingBreadcrumbs}/>
        <CommonService serviceData={AppConstant.detailServices.digitalMarketing}/>
    </Fragment>
  )
}

export default DigitalMarketingService;