import React from 'react'
import { Link } from 'react-router-dom'
import { serviceCardLists } from '../../../Utilites'

const FeatureSection = () => {
   return (
      <section className="feature-area tp-feature-3-bg">
         <div className="container-fluid">
            <div className="row text-center">
               <h2 className='service-title'>We Provide Exclusive <span className='primary-span'>Service</span><br />
               For Your Business Grow</h2>
            </div>
         </div>
         <div className="container">
            <div className="row">
               {serviceCardLists.length > 0 && serviceCardLists.map((sc)=> {
                  return <div className={sc.divClass}>
                     <div className="card">
                        <Link to={sc.link} className="service-card">
                           <span className="card-img">
                              <img className="" src={sc.imgSrc} alt="Card image cap" width={50}/>
                           </span>
                           <div className="card-body">
                              <h4 className="card-title">{sc.title}</h4>
                              <p className="card-text">{sc.description}</p>
                           </div>
                        </Link>
                     </div>
                  </div>
               })}
            </div>
         </div>
      </section>
   )
}

export default FeatureSection