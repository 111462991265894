import React from "react";
import { AppConstant } from "../../AppConstant";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <div className="tp-header-3-top theme-bg-primary d-none d-lg-block">
      <div className="tp-header-3-top-wrap d-flex align-items-center justify-content-between">
        <div className="tp-header-3-top-left d-flex align-items-center">
          <div className="tp-header-3-top-left-item">
            <span>
              <i className="fa-solid fa-location-dot"></i>
            </span>
            <a href="javascript:void(0);">Vadodara, Gujarat, India.</a>
          </div>
          <div className="tp-header-3-top-left-item">
            <span>
              <i className="fa-solid fa-envelope"></i>
            </span>
            <a href={`mailto:${AppConstant.EMAIL.id}`}>
              <span>
                {AppConstant.EMAIL.title}
              </span>
            </a>
          </div>
        </div>
        <div className="tp-header-3-top-right d-flex align-items-center">
          <div className="tp-header-3-top-info">
            <Link to={`/${AppConstant.ROUTES.CONTACT}`}>Help</Link>
            <Link to={`${AppConstant.ROUTES.CONTACT}`}>Support</Link>
            <Link to={`/${AppConstant.ROUTES.CONTACT}`}>Contact</Link>
          </div>
          <div className="tp-header-3-top-social">
            <a href="javascript:void(0);">
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/metafox__" target="_blank">
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://in.linkedin.com/company/meta-fox" target="_blank">
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a href="javascript:void(0);">
              <i className="fa-brands fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
