import React, { Fragment } from 'react'
import MetaTagComponent from '../../../common/Meta/MetaTagComponent'
import TopScreen from '../../../common/TopScreen/TopScreen'
import CommonService from '../Details-page/CommonService'
import { AppConstant } from '../../../../AppConstant'
import { metaMobileAppDevelopment, mobileDevelopmentBreadcrumbs } from '../../../../Utilites'

const MobileAppDevelopment = () => {
  return (
    <Fragment>
        <MetaTagComponent metaDetails={metaMobileAppDevelopment}/>
        <TopScreen breadcrumbDetails={mobileDevelopmentBreadcrumbs}/>
        <CommonService serviceData={AppConstant.detailServices.appDevelopment}/>
    </Fragment>
  )
}

export default MobileAppDevelopment