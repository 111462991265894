import React from "react";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <section className="about-area pt-115 tp-about-3 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="tp-about-3-wrap">
              <div
                className="tp-about-3-thumb-1 tp-thumb-common fix mb-30 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div className="tp-thumb-common-overlay wow"></div>
                <img src="assets/img/thumbs/benifits-4-thumb-1.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-6">
            <div
              className="tp-about-3-wrapper mb-50 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              <div className="tp-section tp-section-3 mb-50">
                <span className="tp-section-sub-title">Welcome to MetaFox</span>
                <h4 className="tp-section-title">
                  Your Trusted Partner for <br />
                  Digital Transformation
                </h4>
                <div className="tp-section-title-wrapper">
                  <p>
                    MetaFox is more than just an IT company. We're a partner
                    dedicated to your success. Our core values of innovation,
                    quality, and customer satisfaction drive us to deliver
                    exceptional solutions. Whether you need a simple website or
                    a complex enterprise application, we've got you covered.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
